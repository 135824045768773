import { type User } from "@prisma/client";
import { type z } from "zod";

import { type schemaBalanceSheetProjectForm } from "./modules/dashboard/schemas";
import { type CaseWithRelations } from "./server/prismaTypes";

export interface NavigationSearchParams {
  searchParams: Record<string, string | string[] | undefined>;
}

export interface NavigationParams<T = object> extends NavigationSearchParams {
  params: T;
}

export enum StringBoolean {
  true = "true",
  false = "false",
}

// CASE
export type CaseUpdate = z.infer<typeof schemaBalanceSheetProjectForm>;

export interface CaseMetadata {
  score: number;
  status: string;
  dayAmountSinceCreation: number;
  priority: string;
  mortgageLineAmount: number;
}

export interface CaseWithMetadata extends CaseMetadata, CaseWithRelations {}

// CUSTOMER
export type CustomerUpdate = z.infer<typeof schemaBalanceSheetProjectForm>;

// USER
export type UserPublic = Omit<User, "password">;
