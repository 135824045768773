import {
  type ElementType,
  type FC,
  type HTMLAttributes,
  type PropsWithChildren,
} from "react";
import { cn } from "~/modules/common/components/utils/styles";

export const textSizeClasses = {
  // TODO REFACTOR make correct variables in Figma and implement here
  txtAlbertSansBold18: "font-albertsans font-bold",
  txtAlbertSansBold70: "font-albertsans font-bold",
  txtAlbertSansBold20: "font-albertsans font-bold",
  h1: "font-albertsans font-bold text-[40px] xl:text-[56px] 2xl:text-[70px] ",
  h1v2: "font-albertsans font-bold text-[20px] md:text-[40px] xl:text-[56px]",
  h2: "font-albertsans font-bold text-[24px] xl:text-[32px] 2xl:text-[50px] ",
  subtitle1: "font-barlow font-bold text-[20px] xl:text-[26px] 2xl:text-[30px]",
  subtitle2: "font-barlow text-[20px] xl:text-[26px]  2xl:text-[30px]",
  subtitle3:
    "font-barlow font-semibold text-[18px] xl:text-[20px] 2xl:text-[24px]",
  subtitle4: "font-barlow text-[18px] xl:text-[20px] 2xl:text-[24px]",
  subtitle5: "font-barlow font-bold text-[16px] xl:text-[18px]",
  body1: "font-barlow font-normal text-[18px] 2xl:text-[20px]",
  body2: "font-barlow font-semibold text-[18px] 2xl:text-[20px]",
  body3: "font-barlow font-normal text-[14px]",
  body4: "font-barlow font-semibold text-[14px]",
  button: "font-barlow font-bold text-[20px] 2xl:text-[24px]",
  emphasis: "font-barlow font-semibold text-[16px]",
  caption: "font-barlow text-[16px]",
  //new text sizes
  display1: "font-albertsans font-semibold text-[40px] xl:text-[84px]",
  display2: "font-albertsans font-normal text-[34px] xl:text-[56px]",
  heading1:
    "font-albertsans font-semibold text-[36px] xl:text-[50px] leading-[42px] xl:leading-[60px]",
  heading2:
    "font-albertsans font-semibold text-[30px] xl:text-[44px] leading-[36px] xl:leading-[54px]",
  heading3:
    "font-albertsans font-semibold text-[26px] xl:text-[32px] leading-[32px] xl:leading-[38px]",
  heading4: "font-albertsans font-semibold text-[24px] leading-[32px]",
  heading5: "font-albertsans font-semibold text-[20px] leading-[30px]",
  heading6: "font-albertsans font-semibold text-[18px] leading-[28px]",
  bodyM: "font-albertsans font-normal text-[18px] leading-[26px]",
  bodyRegular: "font-albertsans font-normal text-[16px] leading-[24px]",
  bodyS: "font-albertsans font-normal text-[14px] leading-[18px]",
  lead: "font-albertsans font-medium text-[14px] leading-[20px]",
  blockquotes: "font-albertsans font-medium text-[18px] leading-[26px]",
  capitalized:
    "font-albertsans font-normal text-[14px] leading-[18px] uppercase letter-spacing-[1.2px]",
  medium: "font-albertsans font-medium text-[16px] leading-[22px]",
  light: "font-albertsans font-light text-[16px] leading-[22px]",
  small: "font-albertsans font-light text-[14px] leading-[18px]",
  tiny: "font-albertsans font-normal text-[12px] leading-[16px]",
} as const;

export interface TextProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  size?: keyof typeof textSizeClasses;
  as?: ElementType;
}

const Text: FC<PropsWithChildren<TextProps>> = (props) => {
  const { children, className, size, as, ...rest } = props;
  const Component = as ?? "span";

  return (
    <Component
      className={cn(size && textSizeClasses[size], className)}
      {...rest}
    >
      {children}
    </Component>
  );
};

export { Text };
